import { useRoutes, Navigate } from "react-router-dom";
import { Routes } from "./routes";
import { lazy } from "react";

const Router = () => {

    const routes = useRoutes([
        {
            path: '/',
            index: true,
            element: <Navigate replace to={"/login"} />,
        },
        
        ...Routes,
    ])


    return routes
}


export default Router