import './App.css';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import Router from '../src/router/Router';
import { StoreContext } from './store';
import LoaderRing from './components/ui/common/LoaderRing';
import { Store } from './store/Store';
import { useLocation } from 'react-router-dom';

function App() {

    // const store = useContext(StoreContext);
    // const store = React.useRef(null);

    const [store] = useState(new Store())

    const location = useLocation();

    useEffect(() => {
      // Get the current path
      const path = location.pathname;
  
      // Check if the path contains uppercase letters
      if (/[A-Z]/.test(path)) {
        // If it does, convert it to lowercase and redirect
        const lowerCasePath = path.toLowerCase();
        window.location.replace(lowerCasePath + window.location.search); // Preserve query params
      }
    }, [location.pathname]);
    return (
        <StoreContext.Provider value={store}>
            <div className='app select-none overflow-hidden'>
                <Suspense fallback={<LoaderRing />}>
                    <Router />
                </Suspense>
                <Toaster position="top-right" reverseOrder={false}>
                    {(t) => (
                        <ToastBar toast={t}>
                            {({ icon, message }) => (
                                <>
                                    {t.type !== 'loading' && (
                                        <button className='rounded-full focus:outline-none flex items-center' onClick={() => toast.dismiss(t.id)} >
                                            {icon}
                                            {message}
                                        </button>
                                    )}
                                </>
                            )}
                        </ToastBar>
                    )}
                </Toaster>
            </div>
        </StoreContext.Provider>
    );
}

export default App;
