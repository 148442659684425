import React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../utility/hooks/UseStore"
import VideoResourcesPanel from "./panel/VideoResourcesPanel";
import AudioResourcesPanel from "./panel/AudioResourcesPanel";
import TextInputPanel from "./panel/TextInputPanel";
import SpeechPanel from "./panel/SpeechPanel";
import SpeechSidePanel from "./panel/SpeechSidePanel";
import { AnimationsPanel } from "./panel/AnimationsPanel";
import { EffectsPanel } from "./panel/EffectPanel";
import ExportVideoPanel from "./panel/ExportVideoPanel";
import FillPanel from "./panel/FillPanel";
import YourDataResourcesPanel from "./panel/YourDataPanel";
import { ButtonLinkPanel, defaultProps as buttonLinkPanelDefaultProps } from "./panel/ButtonLinkPanels";
import { ButtonPanel } from "./panel/ButtonPanel";
import { TextResourcesPanel } from "./panel/TextResourcesPanel";
import { NoItemSelectedPanel } from "./panel/NoItemSelectedPanel";
import LayerPanel from "./panel/LayerPanel";

export const EditorOptions = observer(() => {
  const mainStore = useStore();
  const store = mainStore.videoStore;

  const selectedMenuOption = store.selectedMenuOption;

  return (
    <div className="bg-[#272836] h-full w-[100%]">
      {selectedMenuOption === "video" ? <VideoResourcesPanel /> : null}
      {/* {selectedMenuOption === "Audio" ? <AudioResourcesPanel /> : null} */}
      {selectedMenuOption === "text" ? <TextInputPanel /> : null}
      {selectedMenuOption === "text to speech" ? < SpeechPanel /> : null}
      {selectedMenuOption === "Speech Sidepanel" ? < SpeechSidePanel /> : null}
      {selectedMenuOption === "Animation" ? <AnimationsPanel /> : null}
      {selectedMenuOption === "Effect" ? <EffectsPanel /> : null}
      {selectedMenuOption === "Export" ? <ExportVideoPanel /> : null}
      {selectedMenuOption === "Fill" ? <FillPanel /> : null}
      {selectedMenuOption === "Your Data" ? <YourDataResourcesPanel /> : null}
      {selectedMenuOption === "button" && <ButtonLinkPanel {...buttonLinkPanelDefaultProps} />}
      {selectedMenuOption === "Button Link" ? <ButtonPanel /> : null}
      {selectedMenuOption === "TextPanel" ? <TextResourcesPanel /> : null}
      {selectedMenuOption === "NoSelectedItem" ? <NoItemSelectedPanel /> : null}
      {selectedMenuOption === "layers" ? <LayerPanel /> : null}
    </div>
  );
});

export default EditorOptions