import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../utility/hooks/UseStore";
import a_icon from "../../../../assets/icons/a.svg";
import a2_icon from "../../../../assets/icons/a2.svg";
import text_left_icon from "../../../../assets/icons/text_left.svg";
import text_center_icon from "../../../../assets/icons/text_center.svg";
import text_right_icon from "../../../../assets/icons/text_right.svg";
import capital_text_icon from "../../../../assets/icons/capital_text.svg";
import small_text_icon from "../../../../assets/icons/small_text.svg";
import underline_icon from "../../../../assets/icons/underline.svg";
import italic_icon from "../../../../assets/icons/italic_font.svg";
import opacity_icon from "../../../../assets/icons/opacity.svg";
import stroke_icon from "../../../../assets/icons/stroke.svg";
import radius_icon from "../../../../assets/icons/radius.svg";
import w_icon from "../../../../assets/icons/w.svg";
import h_icon from "../../../../assets/icons/h.svg";
import droparrow from "../../../../assets/dropdown-arrow.svg";
import url_link_icon from "../../../../assets/icons/url_link.svg";
import timeline_icon from "../../../../assets/icons/timeline.svg";
import add_icon from "../../../../assets/icons/add.svg";
import toast from "react-hot-toast";
import padding_x_icon from "../../../../assets/icons/paddingx.svg";
import padding_y_icon from "../../../../assets/icons/paddingy.svg";
import { colorToHex } from "../../../commanComponents/colorTextToHexaCode";
import fonticon from "../../../../assets/font_size.png"
import { fontFamilyJson } from "../../../../configs/fontFamily";
import { fontLoader } from "../../../../utility/utils";

export const ButtonPanel = observer(() => {
  const [btnTxt, setBtnTxt] = useState("");
  const [fontFamily, setFontFamily] = useState("");
  const [fontSize, setFontSize] = useState("");

  const [linkUrl, setLinkUrl] = useState("");
  const [fontWeight, setFontWeight] = useState();
  const [fill, setFill] = useState("#FFFFFF");
  const [btnBg, setBtnBg] = useState("transparent");
  const [btnWidth, setBtnWidth] = useState();
  const [underLine, setUnderLine] = useState(false);
  const [italic, setItalic] = useState("");
  const [textAlign, setTextAlign] = useState("");
  const [btnHeight, setBtnHeight] = useState();
  const [border, setBorder] = useState();
  const [borderColor, setBorderColor] = useState("#000000");
  const [borderRadius, setBorderRadius] = useState();

  const [position, setPosition] = useState();
  const [txtOpacity, setTxtOpacity] = useState();
  const [btnOpacity, setBtnOpacity] = useState();
  const [charSpacing, setcharSpacing] = useState();
  const [lineHeight, setlineHeight] = useState();
  const [paddingX, setPaddingX] = useState(0);
  const [paddingY, setPaddingY] = useState(0);
  const [positionX, setpositionX] = useState(0);
  const [positionY, setpositionY] = useState(0);
  const [selectedButton, setSelectedButton] = useState(null);

  const mainStore = useStore();
  const store = mainStore.videoStore;
  const selectedElement = store.selectedElement || {};
  const editorElement = selectedElement?.id;

  const handleBtnTxtChange = (event) => {
    const newText = event.target.value;
    setBtnTxt(newText);
    if (editorElement) {
      store.updateText(editorElement, newText);
    }
  };


  const [error, setError] = useState("")
  const [runValidator, setRunValidator] = useState(false)
  const websiteRegex = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;

  const validate = () => {
      let errors = "";
      let isErrorFound = false

      // Email validation
      if (!linkUrl.trim()) {
          errors = 'Button Link is required.';
          isErrorFound = true
      } else if (!websiteRegex.test(linkUrl)) {
          errors = 'Button Link is invalid.';
          isErrorFound = true
      } else {
          setError("")
      }

      setError(errors);
      return isErrorFound
  };

  useEffect(() => {
      const timer = setTimeout(() => {
          if (runValidator) {
              validate()
          }
      }, 150)

      return () => clearTimeout(timer)
  }, [linkUrl])

  // this is for hanlding padding
  const handlePadding = (from, e) => {
    if (from === "x") {
      if(e.target.value > 50) { 
        e.target.value = 50;  // Prevent value from going over 100
      }else{
      store.handlePaddingForText(editorElement, from, e.target.value);
      setPaddingX(e.target.value);
      }
    } else if (from === "y") {
      if(e.target.value > 50) { 
        e.target.value = 50;  // Prevent value from going over 100
      }else{
      store.handlePaddingForText(editorElement, from, e.target.value);
      setPaddingY(e.target.value);
      }
    }
  };

  // const handleFontFamilyChange = (e) => {
  //   const newFontFamily = e.target.value;
  //   setFontFamily(newFontFamily);

  //   if (selectedButton) {
  //     selectedButton.set("fontFamily", newFontFamily);
  //     store.canvas?.renderAll();
  //   }

  //   if (editorElement) {
  //     store.updateFontFamily(editorElement, newFontFamily);
  //   }
  // };
  const handleFontFamilyChange = (e) => {
    function cb(name, isWeightAvail, weight) {

        if(!isWeightAvail){
            const newWeight = parseInt(weight);
            setFontWeight(newWeight);
            if (selectedButton) {
                selectedButton.set("fontWeight", newWeight);
            }
            if (editorElement) {
                store.updateFontWeight(editorElement, newWeight);
            }
        }

        
        setFontFamily(name);
        if (editorElement) {
            console.log(editorElement)
            store.updateFontFamily(editorElement, name);
        }
    }
    const object = fontFamilyJson.find(item => item.name === e.target.value)
    const weights = object.availableWeights
    fontLoader(e.target.value, object, weights, fontWeight, cb)
};

  const handleFontSizeChange = (e) => {
    const newSize = parseFloat(e.target.value);
    if(e.target.value > 20) {
      e.target.value = 20;  // Prevent value from going over 100
    }else{
    setFontSize(newSize);
    if (editorElement) {
      store.updateFontSize(editorElement, newSize);
    }
  }
  };

  const handelFontWeight = (e) => {
    const newWeight = parseInt(e.target.value, 10);
    setFontWeight(newWeight);
    if (selectedButton) {
      selectedButton.set("fontWeight", newWeight);
      store.canvas?.renderAll();
    }
    if (editorElement) {
      store.updateFontWeight(editorElement, newWeight);
    }
  };

  const handleFontColorChange = (e) => {
    const newColor = e.target.value;

    setFill(newColor);
    if (selectedButton) {
      selectedButton.set("fill", newColor);
      store.canvas?.renderAll();
    }
    if (editorElement) {
      // store.updateFill(editorElement, newColor)
      store.updateBtnTextColor(editorElement, newColor);
    }
  };

  const handleBtnBgChange = (e) => {
    const newColor = e.target.value;
    setBtnBg(newColor);
    if (selectedButton) {
      selectedButton.set("backgroundColor", newColor);
      store.canvas?.renderAll();
    }
    if (editorElement) {
      store.updateBackgroundColor(editorElement, newColor);
    }
  };

  const handleLinkInputChange = (event) => {
    const newLink = event.target.value;
    setLinkUrl(newLink);
    if (editorElement) {
      store.updateButtonLink(editorElement, newLink);
    }
  };

  const handlebtnWidthChange = (e) => {
    const newWidth = parseInt(e.target.value);
    setBtnWidth(newWidth);
    if (editorElement) {
      store.updateWidth(editorElement, newWidth);
    }
  };

  const handleAddButton = (e) => {
    e.preventDefault();
     const isError = validate()
    setRunValidator(true)
    if (isError) return;
    store.setSelectedMenuOption("button")
  }

  const handlebtnHeightChange = (e) => {
    const newHeight = parseInt(e.target.value);
    setBtnHeight(newHeight);

    if (editorElement) {
      store.updateHeight(editorElement, newHeight);
    }
  };

  const handleUnderLine = (e) => {
    setUnderLine(!underLine);
    if (editorElement) {
      store.updateUnderLine(editorElement, !underLine);
    }
  };

  const handleItalic = (e) => {
    const newItalic = italic === "normal" ? "italic" : "normal";

    setItalic(newItalic);
    if (editorElement) {
      store.updateItalic(editorElement, newItalic);
    }
  };

  let cord;
  // const handlePosition = (position) => {
  //   setPosition(position);
  //   if (editorElement) {
  //     if (position === "left") {
  //       cord = 0;
  //     } else if (position === "right") {
  //       if (!store) return;

  //       const canvasWidth = store?.canvas?.width || 0;
  //       const buttonWidth =
  //         store.editorElements.find((item) => item.id === editorElement)
  //           ?.fabricObject?.width || 0;

  //       const scaleX =
  //         store.editorElements.find((item) => item.id === editorElement)
  //           ?.fabricObject?.scaleX || 0;

  //       const scaledWidth = buttonWidth * scaleX;
  //       cord = canvasWidth - scaledWidth;
  //     } else if (position === "center") {
  //       if (!store) return;
  //       // const canvasWidth = store?.canvas?.width / 2 ;
  //       const canvasWidth = store?.canvas?.width || 0;
  //       const canvasHalfWidth = canvasWidth / 2;

  //       // const buttonWidth = store.editorElements.find(
  //       //   (item) => item.id === editorElement
  //       // )?.placement.width / 2;

  //       const buttonWidth =
  //         store.editorElements.find((item) => item.id === editorElement)
  //           ?.fabricObject.width || 0;

  //       const buttonHalfWidth = buttonWidth / 2;

  //       const scaleX =
  //         store.editorElements.find((item) => item.id === editorElement)
  //           ?.fabricObject.scaleX || 0;
  //       const scaledWidth = buttonHalfWidth * scaleX;

  //       // cord = canvasWidth - scaledWidth;
  //       cord = canvasHalfWidth - scaledWidth;
  //     }
  //   }
  //   if (editorElement) {
  //     store.handleAlign(editorElement, cord);
  //     setPosition(position);
  //   }
  // };

  const handleBorder = (e) => {
    const newBorder = parseFloat(e.target.value);
    if(e.target.value > 15) {
      e.target.value = 15; 
    }else{
    setBorder(newBorder);
    if (editorElement) {
      store.updateBorder(editorElement, newBorder);
    }
  }
  };

  const handleBorderColorChange = (e) => {
    const newBorderColor = e.target.value;
    setBorderColor(newBorderColor);
    if (editorElement) {
      store.updateBorderColor(editorElement, newBorderColor);
    }
  };

  const handleBorderRadius = (e) => {
    const newBorderRadius = e.target.value;
    setBorderRadius(newBorderRadius);
    if (editorElement) {
      store.updateBorderRadius(editorElement, newBorderRadius);
    }
  };

  const handleUppercase = (e) => {
    if (editorElement) {
      store.updateUppercase(editorElement);
      const text = btnTxt;
      if (text !== text.toUpperCase()) {
        setBtnTxt(text?.toUpperCase());
      } else {
        setBtnTxt(text?.toLowerCase());
      }
    }
  };

  const handleLowercase = (e) => {
    if (editorElement) {
      store.updateLowercase(editorElement);
      const text = btnTxt;
      if (text !== text?.toLowerCase()) {
        setBtnTxt(text?.toLowerCase());
      } else {
        setBtnTxt(text?.toUpperCase());
      }
    }
  };

  const handleKeyDown = (e) => {
    // Check if the pressed key is '.' (decimal point) or any non-numeric key
    if (e.key === "." || (e.key !== "Backspace" && e.key !== "Delete" && isNaN(Number(e.key)))) {
      e.preventDefault();
    }
  };
  const handleTxtOpacityChange = (e) => {
    const newOpacity = parseInt(e.target.value);
    if (e.target.value > 100) {
      e.target.value = 100;  // Prevent value from going over 100
    }else{
    setTxtOpacity(newOpacity);
    if (editorElement) {
      store.updateTxtOpacity(editorElement, newOpacity / 100);
    }
    }
  };

  const handleBtnOpacityChange = (e) => {
    const newOpacity = parseInt(e.target.value);
    if (e.target.value > 100) {
      e.target.value = 100;  // Prevent value from going over 100
    }
    else{
    setBtnOpacity(newOpacity);
    if (editorElement) {
      store.updateBtnOpacity(editorElement, newOpacity / 100);
    }
  }
  };

  const handleLineHeightChange = (e) => {
    const newLineHeight = e.target.value;
    if(e.target.value > 5) {
      e.target.value = 5; 
    }else{ 
    setlineHeight(newLineHeight);
    if (editorElement) {
      store.updateLineHeight(editorElement, newLineHeight);
    }
  }
  };

  const handleCharSpacingChange = (e) => {
    const newCharSpacing = parseInt(e.target.value);
    if(e.target.value > 50) {
      e.target.value = 50; 
    }else{ 
    setcharSpacing(newCharSpacing);
    if (editorElement) {
      store.updateCharSpacing(editorElement, newCharSpacing * 10);
    }
  }
  };

  useEffect(() => {
    if (store.selectedElement && store.selectedElement.type == "button") {
      const element = store.selectedElement;

      const newDAta = JSON.parse(
        JSON.stringify(store.selectedElement.fabricObject)
      );
      const buttonLink = JSON.parse(
        JSON.stringify(store.selectedElement.properties.linkUrl)
      );

      const dataRect = newDAta ? newDAta.objects[0] : null;
      const dataText = newDAta ? newDAta.objects[1] : null;

      
      setBtnTxt(element.properties.text);
      setFontFamily(element.properties.fontFamily);
      setFontSize(element.properties.fontSize);
      setFontWeight(element.properties.fontWeight);
      setlineHeight(element.properties.lineHeight);
      setcharSpacing(element.properties.charSpacing / 10);
      setUnderLine(element.properties.underline);
      setItalic(element.properties.fontStyle);
      setFill(element.properties.textColor || "#000000");
      setTxtOpacity(element.properties.txtOpacity * 100);
      setBorder(element.properties.strokeWidth);
      setBtnWidth(element.properties.paddingX);
      setBtnHeight(element.properties.paddingY);
      setPaddingX(element.properties.paddingX);
      setPaddingY(element.properties.paddingY);

      setBtnBg(dataRect?.fill);
      setBtnOpacity(element.properties.btnOpacity * 100);
      setBorderColor(dataRect?.stroke);
      setBorderRadius(element.properties.borderRadius);
      setpositionX(parseFloat(element.placement.x)?.toFixed(2))
      setpositionY(parseFloat(element.placement.y)?.toFixed(2))
      if (buttonLink) {
        setLinkUrl(buttonLink);
      }
    }
  }, [store.selectedElement]);

  // this is all logic for converting miliseconds and handle input text
  const [startTimeValue, setStartTimeValue] = useState({
    min: 0,
    sec: 0,
    miliSec: 0,
  });
  const [endTimeValue, setEndTimeValue] = useState({
    min: 0,
    sec: 0,
    miliSec: 0,
  });

  useEffect(() => {
    const startTime = selectedElement?.timeFrame?.start;
    // const startMin = Math.floor(startTime / 60)
    // const startSec = Math.floor(startTime % 60)
    // const startMiliSec = Math.floor((startTime - Math.floor(startTime)) * 1000)

    const startMin = Math.floor(startTime / 60000);
    const startSec = Math.floor((startTime - startMin * 60000) / 1000);
    const startMiliSec = startTime - startMin * 60000 - startSec * 1000;

    setStartTimeValue({
      min: startMin,
      sec: startSec,
      miliSec: startMiliSec,
    });

    const endTime = selectedElement?.timeFrame?.end;
    const endMin = Math.floor(endTime / 60000);
    const endSec = Math.floor((endTime - endMin * 60000) / 1000);
    const endMiliSec = endTime - endMin * 60000 - endSec * 1000;
    setEndTimeValue({
      min: endMin,
      sec: endSec,
      miliSec: endMiliSec,
    });
  }, [
    selectedElement?.timeFrame?.start,
    selectedElement?.timeFrame?.end,
  ]);

  const handleTimeChange = (e, from) => {
    if (from === "start") {
      const { name, value } = e.target;

      const tempState = {
        min: startTimeValue.min,
        sec: startTimeValue.sec,
        miliSec: startTimeValue.miliSec,
      };

      tempState[name] = value;

      const min = Number(tempState.min);
      const sec = Number(tempState.sec);
      const miliSec = Number(tempState.miliSec);
      const total_milliseconds =
        Number(min * 60 * 1000) + Number(sec * 1000) + Number(miliSec);
      if (
        Number(total_milliseconds) >= Number(store.maxTime) ||
        Number(total_milliseconds) < 0 ||
        Number(total_milliseconds) >=
          Number(store.selectedElement.timeFrame.end)
      ) {
        toast.error(
          "Time should be less than video duration and not should be negative nor greater than end time"
        );
        return;
      } else {
        store.updateStartEndTime(
          editorElement,
          "start",
          Number(total_milliseconds)
        );
        setStartTimeValue({
          ...tempState,
        });
      }
    } else if (from === "end") {
      const { name, value } = e.target;

      const tempState = {
        min: endTimeValue.min,
        sec: endTimeValue.sec,
        miliSec: endTimeValue.miliSec,
      };

      tempState[name] = value;

      const min = Number(tempState.min);
      const sec = Number(tempState.sec);
      const miliSec = Number(tempState.miliSec);

      const total_milliseconds =
        Number(min * 60 * 1000) + Number(sec * 1000) + Number(miliSec);

      if (
        Number(total_milliseconds) > Number(store.maxTime) &&
        Number(total_milliseconds) <
          Number(store.selectedElement.timeFrame.start)
      ) {
        toast.error("Time should be less than video duration");
        return;
      } else {
        store.updateStartEndTime(editorElement, "end", total_milliseconds);
        setEndTimeValue({
          ...tempState,
        });
      }
    }
  };


    // this is for handling Position
    const handlePosition = (from, e) => {
      if (from === "x") {
        store.handleTextPosition(editorElement, from, e.target.value);
        setpositionX(parseFloat(e.target.value)?.toFixed(2));
      } else if (from === "y") {
        store.handleTextPosition(editorElement, from, e.target.value);
        setpositionY(parseFloat(e.target.value)?.toFixed(2));
      }
    };

    const textAlingment = (position) => {
      if (editorElement) {
        store.handleTextAling(editorElement, position);
      }
    };


  return (
    <>
      <div className="px-[10px] 2xl:px-[20px] pt-[10px] 2xl:pt-[20px]">
        {/* <h1 className="text-white font-Inter font-semibold text-[18px]">Button</h1> */}
        <div className="">
          <h3 className="font-Inter font-medium text-sm text-[#ABABC0]">
            Text
          </h3>
          <textarea
            className="p-[5px] 2xl:p-[10px] bg-[#3B3B4F] rounded mt-[8px] 2xl:mt-[16px] outline-none w-full font-Inter font-normal text-sm text-[#FFFFFF] h-[60px] 2xl:h-[100px]"
            value={btnTxt}
            onChange={handleBtnTxtChange}
            required
            placeholder="Please enter text"
          />
          <div className="py-[12px] relative  font-Inter font-normal text-sm text-[#FFFFFF]">
          <select value={fontFamily} onChange={(e) => { handleFontFamilyChange(e) }} className={`w-full  appearance-none rounded bg-[#3B3B4F] p-[10px] outline-none capitalize`}>
                            {fontFamilyJson.map((item, i) => {
                                return (
                                    <option value={item.name} className="capitalize">{item.name}</option>
                                )
                            })
                            }

                        </select>
            <img
              src={droparrow}
              alt="droparrow"
              className=" absolute  transform translate-x-[-50%] top-[45%] right-2"
            ></img>
          </div>

          <div className="pb-[12px]  grid grid-cols-12 2xl:gap-[10px] gap-[5px] font-Inter font-normal text-sm text-[#FFFFFF]">

            <div className="col-span-6 bg-[#3B3B4F] rounded p-[10px] flex relative items-center gap-[12px]">
              <img src={fonticon} alt="fonticon" className=" peer" />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Font Size</span>
              <input
                type="number"
                value={fontSize}
                className="bg-transparent w-full font-Inter font-medium text-sm text-[#FFFFFF] outline-none placeholder-white"
                // placeholder="100"
                onChange={handleFontSizeChange}
                step={0.01}
                min={3}
                max={20}
              />
            </div>

            <div className="col-span-6 relative">
            <select
                style={{ fontFamily: fontFamily }}
                value={fontWeight}
                onChange={handelFontWeight}
                className={`appearance-none 2xl:text-[14px] text-[12px] rounded w-full bg-[#3B3B4F] p-[10px] outline-none`}
              >
                {fontFamilyJson
                  .find((item) => item.name === fontFamily)
                  ?.availableWeights.map((item, i) => {
                    return (
                      <option
                        key={`fontWeight${i}`}
                        style={{ fontFamily: fontFamily }}
                        value={item}
                        className={`${
                          item === 100
                            ? "font-thin text-[12px]"
                            : item === 200
                            ? "font-extralight text-[13px]"
                            : item === 300
                            ? "font-light text-[14px]"
                            : item === 400
                            ? "font-normal text-[15px]"
                            : item === 500
                            ? "font-medium text-[16px]"
                            : item === 600
                            ? "font-semibold text-[17px]"
                            : item === 700
                            ? "font-bold text-[18px]"
                            : item === 800
                            ? "font-extrabold text-[19px]"
                            : "font-black text-[20px]"
                        }`}
                      >
                        {item === 100
                          ? "Thin"
                          : item === 200
                          ? "Extra Light"
                          : item === 300
                          ? "Light"
                          : item === 400
                          ? "Normal"
                          : item === 500
                          ? "Medium"
                          : item === 600
                          ? "Semi Bold"
                          : item === 700
                          ? "Bold"
                          : item === 800
                          ? "Extra Bold"
                          : "Black"}
                      </option>
                    );
                  })}
                {/* <option value="200" className={`font-[${fontFamily}] font-extralight`}>Extra Light</option>
                                <option value="300" className={`font-[${fontFamily}] font-light`}>Light</option>
                                <option value="400" className={`font-[${fontFamily}] font-normal`}>Normal</option>
                                <option value="500" className={`font-[${fontFamily}] font-medium`}>Medium</option>
                                <option value="600" className={`font-[${fontFamily}] font-semibold`}>Semi Bold</option>
                                <option value="700" className={`font-[${fontFamily}] font-bold`}>Bold</option>fontFamily
                                <option value="800" className={`font-[${fontFamily}] font-extrabold`}>Extra Bold</option>
                                <option value="900" className={`font-[${fontFamily}] font-black`}>Black</option> */}
              </select>
              <img
                src={droparrow}
                alt="droparrow"
                className=" absolute  transform translate-x-[-50%] top-[45%] right-1 2xl:right-2"
              ></img>
            </div>
          </div>

          <div className="pb-[12px] grid grid-cols-12 2xl:gap-[10px] gap-[5px]">
            <div className="col-span-6 bg-[#3B3B4F] rounded p-[10px] flex items-center relative gap-[15px]">
              <img src={a_icon} alt="A" className=" peer" />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Line Height</span>
              <input
                type="number"
                value={lineHeight}
                onChange={handleLineHeightChange}
                className="bg-transparent w-full font-Inter font-medium text-sm text-[#FFFFFF] outline-none placeholder-white"
                placeholder="0"
                min={1}
                max={5}
                step={0.01}
              />
            </div>

            <div className="col-span-6 bg-[#3B3B4F] rounded p-[10px] flex items-center gap-[15px] relative">
              <img src={a2_icon} alt="A2" className=" peer" />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Letter Spacing</span>
              <input
                value={charSpacing}
                onChange={handleCharSpacingChange}
                type="number"
                className="bg-transparent w-full font-Inter font-medium text-sm text-[#FFFFFF] outline-none placeholder-white"
                placeholder="0"
                step={1}
                max={50}
                min={1}
              />
            </div>
          </div>

          <div className="grid grid-cols-10 lg:grid-cols-7 2xl:grid-cols-10 gap-[5px]">
            <div
              onClick={() => textAlingment("left")}
              className="col-span-2 lg:col-span-1 2xl:col-span-2 bg-[#3B3B4F] py-[6px]  px-[8px] rounded cursor-pointer"
            >
              <img
                src={text_left_icon}
                alt="TextLeft"
                className="h-[20px] mx-auto w-[20px]"
              />
            </div>
            <div
              onClick={() => textAlingment("center")}
              className="col-span-2 lg:col-span-1 2xl:col-span-2 bg-[#3B3B4F] py-[6px]  px-[8px] rounded cursor-pointer"
            >
              <img
                src={text_center_icon}
                alt="TextCenter"
                className="h-[20px] mx-auto w-[20px]"
              />
            </div>
            <div
              onClick={() => textAlingment("right")}
              className="col-span-2 lg:col-span-1 2xl:col-span-2 bg-[#3B3B4F] py-[6px]  px-[8px] rounded cursor-pointer"
            >
              <img
                src={text_right_icon}
                alt="TextRight"
                className="h-[20px] mx-auto w-[20px]"
              />
            </div>
            <div
              onClick={handleUppercase}
              className="col-span-2 lg:col-span-1 2xl:col-span-2 bg-[#3B3B4F] py-[6px]  px-[8px] rounded cursor-pointer"
            >
              <img
                src={capital_text_icon}
                alt="CapitalText"
                className="h-[20px] mx-auto w-[20px]"
              />
            </div>
            <div
              onClick={handleLowercase}
              className="col-span-2 lg:col-span-1 2xl:col-span-2 bg-[#3B3B4F] py-[6px]  px-[8px] rounded cursor-pointer"
            >
              <img
                src={small_text_icon}
                alt="SmallText"
                className="h-[20px] mx-auto w-[20px]"
              />
            </div>
            <div
              onClick={handleUnderLine}
              className="col-span-2 lg:col-span-1 2xl:col-span-2 bg-[#3B3B4F] py-[6px] px-[8px] rounded cursor-pointer"
            >
              <img
                src={underline_icon}
                alt="UnderLine"
                className="h-[20px] mx-auto w-[20px]"
              />
            </div>
            <div
              onClick={handleItalic}
              className="col-span-2 lg:col-span-1 2xl:col-span-2 bg-[#3B3B4F] py-[6px] px-[8px] rounded cursor-pointer"
            >
              <img
                src={italic_icon}
                alt="ItalicFont"
                className="h-[20px] mx-auto w-[20px]"
              />
            </div>
          </div>

          <div className=" pt-[12px] grid grid-cols-12 gap-[5px] 2xl:gap-[10px]">
            <div className="col-span-6 bg-[#3B3B4F] flex items-center gap-[5px] rounded p-[10px]">
              {/* <label id="color1">
                            <input id="color1" className="w-[25px] h-[25px] m-0 appearance-none bg-transparent outline-none cursor-pointer" type="color" value={fill} onChange={handleFontColorChange} />
                            <span className="text-[#FFFFFF] font-Inter font-normal text-sm uppercase">{fill}</span>
                            </label> */}
              <input
                id="color1"
                className="w-[25px] h-[25px] m-0 appearance-none bg-transparent outline-none cursor-pointer"
                type="color"
                value={fill.includes("#") ? fill : colorToHex(fill)}
                onChange={handleFontColorChange}
                placeholder="#FFFFFF"
              />
              <input
                type="text"
                value={fill}
                className="text-[#FFFFFF] outline-none font-Inter font-normal text-sm uppercase bg-transparent w-[calc(100%-25px)]"
                placeholder="#FFFFFF"
                onChange={handleFontColorChange}
                maxLength={20}
              />
            </div>

            <div className="col-span-6 p-[10px] flex items-center gap-[15px] bg-[#3B3B4F] rounded relative">
              <img src={opacity_icon} alt="Eye" className=" peer w-[15px] h-auto object-contain 2xl:w-auto" />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Opacity</span>
              <input
                type="number"
                min={0}
                max={100}
                value={txtOpacity}
                onChange={handleTxtOpacityChange}
                step={1}
                onKeyDown={handleKeyDown}
                className="w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <hr className="bg-[#3B3B4F] border-0  h-[1px]" />

        {/* Border  */}
        <div className="pt-[10px] 2xl:pt-[20px]">
          <h3 className="font-Inter font-medium text-sm text-[#ABABC0]">
            {" "}
            Border
          </h3>

          <div className="py-[10px] 2xl:py-[20px] grid grid-cols-12 gap-[5px] 2xl:gap-[10px]">
            <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] gap-[12px] rounded relative">
              <img
                src={stroke_icon}
                alt="Stroke"
                className=" w-[20px] h-auto object-contain 2xl:w-auto peer"
              />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Border Width</span>
              <input
                type="number"
                min={0}
                step={0.01}
                max={15}
                value={border}
                onChange={handleBorder}
                className="  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                placeholder="0"
              />
            </div>
            <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] gap-[15px] rounded relative">
              <img
                src={radius_icon}
                alt="Radius"
                className=" w-[15px] h-auto object-contain 2xl:w-auto peer"
              />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Border Radius</span>
              <input
                type="number"
                min={0}
                value={borderRadius}
                onChange={handleBorderRadius}
                className=" w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                placeholder="0"
                step={0.01}
              />
            </div>
            {/* <div className="col-span-2 bg-[#3B3B4F] rounded p-[10px]">
                <Image src={Screen} alt="Screen" />
                </div> */}
            <div className="col-span-12 2xl:col-span-6 bg-[#3B3B4F] flex items-center gap-[5px] rounded p-[10px]">
              {/* <input id="color1" className="w-[25px] h-[25px] m-0 appearance-none bg-transparent outline-none cursor-pointer" type="color" value={borderColor} onChange={handleBorderColorChange} />
                            <span className=" text-[#FFFFFF] font-Inter font-normal text-sm uppercase">{borderColor}</span> */}
              <input
                id="color1"
                className="w-[25px] h-[25px] m-0 appearance-none bg-transparent outline-none cursor-pointer"
                type="color"
                value={
                  borderColor.includes("#")
                    ? borderColor
                    : colorToHex(borderColor)
                }
                onChange={handleBorderColorChange}
                placeholder="#FFFFFF"
              />
              <input
                type="text"
                value={borderColor}
                className="text-[#FFFFFF] outline-none font-Inter font-normal text-sm uppercase bg-transparent w-[calc(100%-25px)]"
                placeholder="#000000"
                onChange={handleBorderColorChange}
                maxLength={20}
              />
            </div>

            {/* <div className="col-span-6 p-[10px] flex items-center gap-[30px] bg-[#3B3B4F] rounded">
                <Image src={Eye} alt="Eye" />
                <input
                  type="number"
                  min={0}
                  className="  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                  placeholder="100%"
                />
              </div> */}
          </div>
        </div>
        <hr className="bg-[#3B3B4F] border-0  h-[1px]" />

        {/* Background  */}
        <div className="py-[10px] 2xl:py-[20px] font-Inter text-[#FFFFFF]">
          <h3 className="font-medium text-sm text-[#ABABC0]">Background</h3>

          {/* <div className="pt-[5px] 2xl:pt-[20px] grid grid-cols-12 gap-[5px] 2xl:gap-5">
                        <div className="col-span-6 p-[8px] 2xl:p-[12px] flex bg-[#3B3B4F] gap-[2px] 2xl:gap-1 rounded">
                            <img src={w_icon} alt="width" className="w-[13px] h-[13px] 2xl:w-[15px] 2xl:h-[15px] my-auto" />
                            <input type="number" min={0} className="text-center w-full text-[12px] 2xl:text-[14px] focus:outline-none placeholder-white bg-transparent text-white my-auto " placeholder="300" value={btnWidth} onChange={handlebtnWidthChange}/>
                        </div>
                        <div className="col-span-6 p-[8px] 2xl:p-[12px] flex bg-[#3B3B4F] gap-[2px] 2xl:gap-1 rounded">
                            <img src={h_icon} alt="height" className="w-[13px] h-[13px] 2xl:w-[15px] 2xl:h-[15px] my-auto" />
                            <input type="number" min={0} value={btnHeight} onChange={handlebtnHeightChange} className="text-center w-full text-[12px] 2xl:text-[14px] focus:outline-none placeholder-white bg-transparent text-white my-auto" placeholder="300"/>
                        </div>
                    </div> */}
          <div className=" grid grid-cols-12 2xl:gap-[10px] gap-[5px] pt-[20px]">
            <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] rounded gap-[15px] relative">
              <img
                src={padding_x_icon}
                alt="Angle"
                className=" w-[15px] h-auto object-contain 2xl:w-auto peer"
              />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Padding X</span>
              <input
                type="number"
                min={0}
                max={50}
                step={0.01}
                value={paddingX}
                onChange={(e) => {
                  handlePadding("x", e);
                }}
                className="w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                placeholder="0"
              />
            </div>
            <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] rounded gap-[15px] relative">
              <img
                src={padding_y_icon}
                alt="Radius"
                className=" w-[15px] h-auto object-contain 2xl:w-auto peer"
              />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Padding Y</span>
              <input
                type="number"
                min={0}
                max={50}
                step={0.01}
                value={paddingY}
                onChange={(e) => handlePadding("y", e)}
                className="w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                placeholder="0"
              />
            </div>
          </div>

          {/* <div className=" grid grid-cols-12 gap-5">
                <div className="col-span-5 p-[12px] flex bg-[#3B3B4F] rounded">
                  <Image src={Angle} alt="Angle" />
                  <input
                    type="number"
                    min={0}
                    className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                    placeholder="0"
                  />
                </div>
                <div className="col-span-5 p-[12px] flex bg-[#3B3B4F] rounded">
                  <Image src={Radius} alt="Radius" />
                  <input
                    type="number"
                    min={0}
                    className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                    placeholder="0"
                  />
                </div>
                <div className="col-span-2 bg-[#3B3B4F] rounded p-[10px]">
                  <Image src={Screen} alt="Screen" />
                </div>
              </div> */}

          <div className="2xl:pt-[20px] pt-[10px] grid grid-cols-12 gap-[5px]  2xl:gap-[10px]">
            <div className="col-span-6 bg-[#3B3B4F] flex items-center justify-between gap-[5px] rounded p-[5px] 2xl:p-[10px]">
              {/* <div className="flex items-center gap-[2px] 2xl:gap-3">
                                <input id="color1" className="w-[20px] 2xl:w-[25px] h-[20px] 2xl:h-[25px] m-0 appearance-none bg-transparent outline-none cursor-pointer" type="color" value={btnBg} onChange={handleBtnBgChange} />
                                <span className=" text-[#FFFFFF] font-Inter font-normal uppercase text-[11px] 2xl:text-[14px]">{btnBg ? btnBg : "#000000"}</span>
                            </div> */}
              <input
                id="color1"
                className="w-[25px] h-[25px] m-0 appearance-none bg-transparent outline-none cursor-pointer"
                type="color"
                value={btnBg.includes("#") ? btnBg : colorToHex(btnBg)}
                onChange={handleBtnBgChange}
                placeholder="transparent"
              />
              <input
                type="text"
                value={btnBg}
                className="text-[#FFFFFF] outline-none font-Inter font-normal text-sm uppercase bg-transparent w-[calc(100%-25px)]"
                placeholder="transparent"
                onChange={handleBtnBgChange}
                maxLength={20}
              />
              {/* <span className="">
                <img src={background_icon} alt="background" />
              </span> */}
            </div>

            <div className="col-span-6 p-[12px] flex items-center  2xl:gap-[10px] bg-[#3B3B4F] relative rounded gap-[15px]">
              <img
                src={opacity_icon}
                alt="Eye"
                className=" w-[15px] h-auto object-contain 2xl:w-auto peer"
              />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Opacity</span>
              <input
                type="number"
                min={0}
                max={100}
                value={btnOpacity}
                onKeyDown={handleKeyDown}
                onChange={handleBtnOpacityChange}
                className="w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                placeholder="0"
                step={1}
              />
            </div>
          </div>
        </div>
        <div className=" grid grid-cols-12 gap-[5px] 2xl:gap-[10px] pt-[12px]">
              <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] rounded relative">
                {/* <img
                  // src={position_x}
                  alt="Angle"
                  className=" w-[15px] h-auto object-contain 2xl:w-auto peer"
                /> */}
                <p className="pl-1 peer text-white mr-1">X</p>
                <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Position X</span>
                <input
                  type="number"
                  min={0}
                  step={0.1}
                  value={Number(positionX)}
                  onChange={(e) => {
                    handlePosition("x", e);
                  }}
                  className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                  placeholder="0"
                />
              </div>
              <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] rounded relative">
                {/* <img
                  // src={position_y}
                  alt="Radius"
                  className=" w-[15px] h-auto object-contain 2xl:w-auto peer"
                /> */}
                <p className="pl-1 peer text-white mr-1">Y</p>
                <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Position Y</span>
                <input
                  type="number"
                  min={0}
                  step={0.1}
                  value={Number(positionY)}
                  onChange={(e) => handlePosition("y", e)}
                  className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                  placeholder="0"
                />
              </div>
            </div>
        <hr className="bg-[#3B3B4F] border-0  h-[1px]" />

        <form onSubmit={handleAddButton}>
        {/* URL  */}
        <div className=" py-[10px] 2xl:py-[20px] font-Inter text-sm text-[#FFFFFF] ">
          <h3 className="font-Inter font-medium text-sm text-[#ABABC0]">URL</h3>
          <div className="flex bg-[#3B3B4F] rounded mt-[16px] p-[10px]">
            <img src={url_link_icon} alt="link" />
            <input
              type="url"
              id="url1"
              className="font-normal  bg-[#3B3B4F] ml-[6px] outline-none w-full"
              value={linkUrl}
              disabled
              onChange={handleLinkInputChange}
              placeholder="Paste link here..."
            />
          </div>
          {error.length > 0 && <p className='text-[12px] leading-[14.52px] font-medium text-[#FF5364] mb-[12px]'>{error}</p>}
        </div>
        <hr className="bg-[#3B3B4F] border-0  h-[1px]" />

        {/* Time Line  */}
        <div className="py-[10px] 2xl:py-[20px]">
          <p className=" text-[#ABABC0] font-Inter font-medium text-[14px] pb-[8px] 2xl:pb-[16px]">
            Timeline
          </p>
          <div className=" grid grid-cols-12 gap-[12px]">
           
            <div className="col-span-12 flex items-end">
            <label className=" text-[14px] min-w-[35px] font-Inter self-center font-medium text-[#ABABC0]">
              Start:
            </label>
              <div className="ml-2 2xl:ml-3">
                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                  Min
                </label>
                <div className="flex justify-start items-center">
                  <input
                    onChange={(e) => {
                      handleTimeChange(e, "start");
                    }}
                    name="min"
                    type="number"
                    value={Number(startTimeValue.min).toFixed()}
                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="ml-2 2xl:ml-3">
                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                  Sec
                </label>
                <div className="flex justify-start items-center">
                  <input
                    onChange={(e) => {
                      handleTimeChange(e, "start");
                    }}
                    name="sec"
                    type="number"
                    value={Number(startTimeValue.sec).toFixed()}
                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="ml-2 2xl:ml-3">
                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                  Mili Sec
                </label>
                <div className="flex justify-start items-center">
                  <input
                    onChange={(e) => {
                      handleTimeChange(e, "start");
                    }}
                    name="miliSec"
                    type="number"
                    value={Number(startTimeValue.miliSec).toFixed()}
                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                    placeholder="0"
                  />
                </div>
              </div>
            </div>
         
            <div className="col-span-12 flex items-end">
              <label className=" text-[14px] min-w-[35px] font-Inter self-center font-medium text-[#ABABC0]">
              End:
            </label>
              <div className="ml-2 2xl:ml-3">
                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                  Min
                </label>
                <div className="flex justify-start items-center">
                  <input
                    onChange={(e) => {
                      handleTimeChange(e, "end");
                    }}
                    name="min"
                    type="number"
                    value={Number(endTimeValue.min).toFixed()}
                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="ml-2 2xl:ml-3">
                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                  Sec
                </label>
                <div className="flex justify-start items-center">
                  <input
                    onChange={(e) => {
                      handleTimeChange(e, "end");
                    }}
                    name="sec"
                    type="number"
                    value={Number(endTimeValue.sec).toFixed()}
                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="ml-2 2xl:ml-3">
                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                  Mili Sec
                </label>
                <div className="flex justify-start items-center">
                  <input
                    onChange={(e) => {
                      handleTimeChange(e, "end");
                    }}
                    name="miliSec"
                    type="number"
                    value={Number(endTimeValue.miliSec).toFixed()}
                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                    placeholder="0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="bg-[#3B3B4F] border-0  h-[1px] " />

        {/* Add Button  */}
        <div className="p-[10px] 2xl:p-[20px]">
          <button
            className="flex items-center justify-center gap-[10px] bg-[#5446D0] font-Inter font-medium text-sm text-[#FFFFFF] w-full p-[10px] rounded outline-none"
            type="submit"
          >
            <img src={add_icon} alt="add" />
            Add New Button
          </button>
        </div>
       </form>
      </div>
    </>
  );
});
