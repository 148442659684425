import React, { useEffect, useRef, useState } from 'react'
import { useStore } from '../../../../utility/hooks/UseStore';
import useOutsideClick from '../../../../utility/hooks/useOutsideClick';
import toast from 'react-hot-toast';
import merge_list_icon from '../../../../assets/icons/mergeMenu.svg'
import add_icon from '../../../../assets/icons/add.svg'


const TextInputPanel = () => {

    const ref = useRef(null);

    const mainStore = useStore()
    const store = mainStore.videoStore
    const selectedElement = store.selectedElement
    const editorElement = selectedElement?.id
    const [inputText, setInputText] = useState("")
    const [popupVisible, setPopupVisible] = useState(false)

    useOutsideClick(ref, () => {
        if (popupVisible) {
            setPopupVisible(false)
        }
    })

    const handelAdd = () => {

        // console.log('this is firing ')

        const textConfig = {
            x: 0,//percentage
            y: 0, //percentage
            // width : 0,
            // height : 100,
            text: inputText,
            fontSize: 3, //percentage
            textColor: "#000000",
            backgroundColor: "transparent",
            stroke: '#000000',
            strokeWidth: 0,
            borderRadius: 0,
            paddingX: 2,
            paddingY: 2,
            lineHeight: 1.2, //percentage
            charSpacing: 0,
            fontWeight: 400,
            fontStyle: "normal",
            txtOpacity: 1,
            linkUrl: "",
            fixedWidth: false,
            fontFamily: "roboto",
            underline: false,
            textAlign: "center",
            scaleX: 1,
            scaleY: 1,
            angle: 0,
            btnOpacity: 1,
            outline: false,
            outlineWidth: 0,
        };
        if (inputText.length !== 0) {
            store.addText(textConfig);
            store.setSelectedMenuOption("TextPanel")
        }
    }

    const handlePopup = (item) => {
        setInputText((prevValue) =>
            prevValue + ` {{${item}}}`
        );
        setPopupVisible(false);
    };

    const headers = store.singleCampaignData[0].headers

    return (
        <div className="p-[10px] 2xl:p-[20px] w-full">
            {/* <h1 className="text-white font-Inter font-semibold text-[18px] p-[10px] 2xl:pb-[20px]">
                Text
            </h1> */}
            <div>
                <div className="flex justify-between items-center pb-[10px] 2xl:pb-[20px] relative">
                    <h3 className="font-Inter font-medium text-[#ABABC0] text-[14px] ">Text</h3>
                    <div className='flex justify-center font-Inter font-medium text-[#ABABC0] text-[14px] cursor-pointer' onClick={() => {
                        setPopupVisible(true)
                    }} >
                        <p className='mr-[10px]'>{`{{ Variable }}`}</p>
                        <img src={merge_list_icon} alt="MergList" />
                    </div>
                    {popupVisible && headers?.length > 0 && (
                        <div ref={ref} className='absolute bg-[#1C1C26] p-[10px] gap-[5px] z-50 max-h-[200px] scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent  overflow-auto rounded-[6px] text-[#ABABC0] top-[25px] right-[0px]'>
                            {headers && headers?.map((item, index) => (
                                <div key={index} onClick={() => handlePopup(item)} className="p-[5px] cursor-pointer hover:bg-[#3B3B4F]">
                                    {item}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <textarea
                    value={inputText}
                    // maxLength={70}
                    onChange={(e) => setInputText(e.target.value)}
                    className="w-full bg-[#3B3B4F] rounded p-[10px] h-[70px] 2xl:h-[100px] text-[#FFFFFF] outline-none"
                    placeholder="Please enter text"
                />
                <button onClick={() => { inputText.length > 0 && handelAdd() }} className={`${inputText.length > 0 ? "" : "opacity-[0.5] cursor-not-allowed"} flex items-center justify-center gap-[10px] bg-[#5446D0] font-Inter font-medium text-sm text-[#FFFFFF] py-[10px] rounded outline-none mt-[15px] w-[100%]`}>
                    <img src={add_icon} alt="add" />Add Text
                </button>
            </div>
        </div>
    )
}

export default TextInputPanel