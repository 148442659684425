import React, { useEffect, useState } from "react";
import reset_icon from "../../../../assets/icons/reset.svg";
import minus_icon from "../../../../assets/icons/minus.svg";
import plus_icon from "../../../../assets/icons/plus.svg";
import add_icon from "../../../../assets/icons/add.svg";
import timeline_icon from "../../../../assets/icons/timeline.svg";
import { useStore } from "../../../../utility/hooks/UseStore";
import toast from "react-hot-toast";
import { observer } from "mobx-react";


function SpeechSidePanel() {


    const mainStore = useStore()
    const store = mainStore.videoStore

    const editorElement = store?.selectedElement && store.selectedElement?.id

    const [volume, setVolume] = useState(100);

    const handleVolumeChange = (e) => {
        const newVolume = parseInt(e.target.value);
        // setVolume(parseInt(e.target.value));
        setVolume(newVolume);
    }

    const handleMinusClick = () => {
        if (volume > 0) {
            setVolume((prevVolume) => prevVolume - 1);
        }
    };

    const handlePlusClick = () => {
        if (volume < 100) {
            const newVolume = volume + 10;
            setVolume(newVolume);
        }
    };


    // this is all logic for converting miliseconds and handle input text 
    const [startTimeValue, setStartTimeValue] = useState({
        min: 0,
        sec: 0,
        miliSec: 0,
        diff : 0
    })

    useEffect(() => {
        const finalElement = store?.editorElements?.find((element) => element.id === editorElement);
        const startTime = finalElement?.timeFrame?.start
        const endTime = finalElement?.timeFrame?.end

        const startMin = Math.floor(startTime / 60000);
        const startSec = Math.floor((startTime - (startMin * 60000)) / 1000);
        const startMiliSec = startTime - (startMin * 60000) - (startSec * 1000);

        const diffrenceTime = endTime - startTime
        console.log("diffrenceTime", diffrenceTime)

        setStartTimeValue({
            min: startMin,
            sec: startSec,
            miliSec: startMiliSec,
            diff : diffrenceTime
        })

    }, [store?.selectedElement?.timeFrame?.start])

    const handleTimeChange = (e, from) => {
        if (from === "start") {
            const { name, value } = e.target

            const tempState = {
                min: startTimeValue.min,
                sec: startTimeValue.sec,
                miliSec: startTimeValue.miliSec
            }

            tempState[name] = value

            const min = Number(tempState.min);
            const sec = Number(tempState.sec);
            const miliSec = Number(tempState.miliSec);
            const total_milliseconds = Number(min * 60 * 1000) + Number(sec * 1000) + Number(miliSec)
            if (Number(total_milliseconds) >= Number(store.maxTime) || Number(total_milliseconds) < 0) {
                toast.error("Time should be less than video duration and not should be negative")
                return
            } else {
                console.log("total_milliseconds", total_milliseconds)
                console.log("startTimeValue.diff", startTimeValue.diff)
                store.updateStartEndTime(editorElement, 'start', Number(total_milliseconds))
                store.updateStartEndTime(editorElement, 'end', Number(total_milliseconds) + Number(startTimeValue.diff))
                setStartTimeValue({
                    ...tempState,
                })
            }
        }
    }

    return (
        <div className="">
            {/* <div className="px-[10px] 2xl:px-[20px] py-[20px] pb-[0px]">
                <h1 className="font-Inter font-semibold text-lg text-[#FFFFFF]">Text to Speech</h1>
            </div> */}
            {/* Volume  */}
            <div className="p-[10px] 2xl:p-[20px]">
                {/* <div className="flex justify-between items-center">
                    <h3 className="text-[#ABABC0] font-Inter font-medium text-[14px] pb-[16px]">Volume</h3>
                    <button onClick={() => setVolume(0)} className="flex gap-[6px] items-center outline-none">
                        <img src={reset_icon} alt="Reset" />
                        <span className="font-Inter font-medium text-xs text-[#FFFFFF]">Reset</span>
                    </button>
                </div>
                <div className="flex 2xl:gap-[22px] gap-[10px] justify-between bg-[#3B3B4F] rounded p-[8px] 2xl:p-[12px]">
                    <button className="outline-none" onClick={handleMinusClick}>
                        <img src={minus_icon} alt="minus" />
                    </button>
                    <input type="range" step={1} max={100} className="bg-[#ABABC0] w-full" value={volume} onChange={handleVolumeChange} />
                    <button className="outline-none" onClick={handlePlusClick}>
                        <img src={plus_icon} alt="plus" />
                    </button>
                    <p className="font-Inter font-medium text-sm text-[#FFFFFF]">{volume}%</p>
                </div> */}
                <div className="">
                    <p className=" text-[#ABABC0] font-Inter font-medium text-[14px] pb-[16px]">Timeline</p>
                    <div className="  grid-cols-12 gap-[5px] flex ">
                        <label className=" text-[14px] font-Inter font-medium text-[#ABABC0] self-center">Start:</label>
                        <div className="col-span-12 flex items-center">
                            {/* <div className="self-end ">
                                <div className="rounded-[4px] 2xl:px-[12px] flex col-span-6 2xl:py-[10px] w-[44px] h-[38px] bg-[#3B3B4F] ">
                                        <img src={timeline_icon} alt="radius" className="w-[15px] mx-auto 2xl:w-[18px] object-contain h-auto" />
                                </div>
                            </div> */}
                            <div className="ml-3">
                                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">Min</label>
                                <div className="flex justify-start items-center">
                                    <input onChange={(e) => { handleTimeChange(e, "start") }} name="min" type="number" value={Number(startTimeValue.min).toFixed()} className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white" placeholder="0" />
                                </div>
                            </div>
                            <div className="ml-3">
                                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">Sec</label>
                                <div className="flex justify-start items-center">
                                    <input onChange={(e) => { handleTimeChange(e, "start") }} name="sec" type="number" value={Number(startTimeValue.sec).toFixed()} className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white" placeholder="0" />
                                </div>
                            </div>
                            <div className="ml-3">
                                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">Mili Sec</label>
                                <div className="flex justify-start items-center">
                                    <input onChange={(e) => { handleTimeChange(e, "start") }} name="miliSec" type="number" value={Number(startTimeValue.miliSec).toFixed()} className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white" placeholder="0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="bg-[#3B3B4F] border-0  h-[1px] " />
            {/* Add Button  */}
            <div className="px-[10px] 2xl:px-[20px] py-[20px]">
                <button className="flex items-center justify-center gap-[10px] bg-[#5446D0] font-Inter font-medium text-sm text-[#FFFFFF] w-full p-[10px] rounded outline-none" onClick={() => { store.setSelectedMenuOption("text to speech") }}>
                    <img src={add_icon} alt="add" />
                    Add New Speech
                </button>
            </div>
        </div>
    )
}

export default observer(SpeechSidePanel)