import React, { useRef, useEffect } from 'react';
import { useStore } from '../../../../utility/hooks/UseStore';

function DragableView(props) {
  const mainStore = useStore();
  const store = mainStore.videoStore;

  const ref = useRef({
    div: null,
    isDragging: false,
    initialMouseX: 0,
    lastRequestAnimationFrameId: null,
  });

  const { current: data } = ref;
  const minLeft = props.minLeft || 0;

  function calculateNewValue(mouseX) {
    if (!data.div || !props.total || props.total === 0) return props.value;
    const deltaX = mouseX - data.initialMouseX;
    const deltaValue = (deltaX / data.div.parentElement.clientWidth) * props.total;

    const newValue = props.value + deltaValue;
    const percentage = (newValue / props.total) * 100;
    
    // Return the constrained value
    if (percentage < minLeft) return (minLeft / 100) * props.total;

    return newValue;
  }

  const handleMouseDown = (event) => {
    if (!data.div || props.disabled) return;
    data.isDragging = true;
    data.initialMouseX = event.clientX;
    event.stopPropagation();
    event.preventDefault();
  };

  const handleMouseMove = (event) => {
    if (!data.isDragging || !data.div) return;

    const move = () => {
      const newValue = calculateNewValue(event.clientX);
      data.div.style.left = `${(newValue / props.total) * 100}%`;
    };

    // Use requestAnimationFrame for smoother updates
    if (!data.lastRequestAnimationFrameId) {
      data.lastRequestAnimationFrameId = requestAnimationFrame(() => {
        move();
        data.lastRequestAnimationFrameId = null; // Reset for next frame
      });
    }

    event.stopPropagation();
    event.preventDefault();
  };

  const handleMouseUp = (event) => {
    if (!data.isDragging || !data.div) return;
    data.isDragging = false;

    props.onChange(calculateNewValue(event.clientX));

    event.stopPropagation();
    event.preventDefault();
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      if (data.lastRequestAnimationFrameId) {
        cancelAnimationFrame(data.lastRequestAnimationFrameId);
      }
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]); // Ensure handlers are stable

  return (
    <div
      ref={(r) => {
        data.div = r;
      }}
      className={`absolute ${props.className}`}
      style={{
        left: `${(props.value / props.total) * 100}%`,
        top: 0,
        bottom: 0,
        ...props.style,
      }}
      onMouseDown={handleMouseDown}
    >
      {props.children}
    </div>
  );
}

export default DragableView;
