import axios from "axios";
import { action, computed, makeAutoObservable, makeObservable, observable } from "mobx";
import toast from "react-hot-toast";
import apiService from "../configs/axios";
import { errorStatusHandler } from "../utility/utils";

import { localStorageNames } from "../configs/constants"
const { __USER_DATA } = localStorageNames

export class userStore {

    userData
    userDomainList
    userOpenAiAPIKeys

    constructor() {
        this.userData = {}
        this.userDomainList = []
        this.userOpenAiAPIKeys = {}
        makeObservable(this)
    }

    async getUserData({ userID, loader }) {
        try {
            const userDataResponse = await apiService.get(`/user/${userID}`)
            const finalUserData = await userDataResponse.data
            if (finalUserData.success === true) {
                this.userData = finalUserData.data
                loader(false)
                return this.userData
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                loader(false)
                toast.error(error.response.data.message)
            }
        }
    }

    async updateUserDetails({ userID, finalData, loader, getLoginUserData }) {
        try {
            const updatedUserData = await apiService.patch(`/user/${userID}`, finalData)
            const finalUpdatedData = await updatedUserData.data
            getLoginUserData()
            if (finalUpdatedData.success === true) {
                this.userData = finalUpdatedData.data
                toast.success(finalUpdatedData.message)
                loader(false)
                const userData = JSON.parse(localStorage.getItem(__USER_DATA))
                localStorage.removeItem(__USER_DATA)
                userData.name = finalData.name
                localStorage.setItem(__USER_DATA, JSON.stringify(userData))
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                loader(false)
                toast.error(error.response.data.message)
            }
        }
    }

    async updateUserPasswordDetails({ headers, finalData, loader, setNewPasswordData, navigate }) {
        try {
            const response = await apiService.post('/user/reset-password', finalData, { ...headers })
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                toast.success(finalResponse.message)
                loader(false)
                setNewPasswordData({
                    Current_Password: '',
                    New_Password: '',
                    Confirm_Password: ''
                })
                localStorage.clear()
                navigate("/login")
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                loader(false)
                toast.error(error.response.data.message)
            }
        }
    }

    async registration({ loader, finalData: data, navigate: navigation }) {
        try {
            const registertionRequest = await apiService.post(`/user/register`, data)
            const finalData = await registertionRequest.data
            if (finalData.success === true) {
                loader(false)
                toast.success(finalData.message ,{ duration: 3000 })
                navigation("/login")
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                loader(false)
                toast.error(error.response.data.message)
            }
        }
    }

    async Login({ loader, finalData: data, navigate, setVerificationPendingPopup }) {
        try {
            const loginResponse = await apiService.post('/user/login', data)
            const finalLoginData = await loginResponse.data
            if (finalLoginData.success === true) {
                localStorage.setItem(__USER_DATA, JSON.stringify(finalLoginData.data))
                toast.success(finalLoginData.message)
                navigate('/videos')
                loader(false)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                loader(false)
                if (error.response.data.message === "Email verification pending.") {
                    setVerificationPendingPopup(true)
                }
                toast.error(error.response.data.message)
            }

        }
    }

    async userVerify(Token, loader, setUserName, setVerificationSuccessful) {
        try {
            const verifyUserResponse = await apiService.get(`/user/verify-user?token=${Token}`)
            const verifiedUserResponse = await verifyUserResponse.data
            if (verifiedUserResponse.success === true) {
                this.EmailVerify(Token, loader, setUserName, setVerificationSuccessful)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
            }
            setVerificationSuccessful(false)
            loader(false)
        }
    }
    async EmailVerify(authToken, loader, setUserName, setVerificationSuccessful) {
        try {
            const verifyEmailResponse = await apiService.get(`/user/verify-email?authToken=${authToken}`)
            const verifiedEmailResponse = await verifyEmailResponse.data
            if (verifiedEmailResponse.success === true) {
                toast.success(verifiedEmailResponse.message)
                setUserName(verifiedEmailResponse.name)
                loader(false)
                setVerificationSuccessful(true)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
            }
            setVerificationSuccessful(false)
            loader(false)
        }
    }

    async ForgetPasswordRequest(data, navigate) {
        try {
            const ForgetPasswordRequestResponse = await apiService.post('/user/forgot-password-request', data)
            const finalEmailRequestResponse = await ForgetPasswordRequestResponse.data
            if (finalEmailRequestResponse.success === true) {
                toast.success(finalEmailRequestResponse.message)
                navigate('/login')
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
            }
        }
    }

    async setNewPassword({ token, finalData, navigate, loader }) {
        try {
            const setNewPasswordResponse = await apiService.patch(`/user/forgot-password?token=${token}`, finalData)
            const finalSetNewPasswordResponse = await setNewPasswordResponse.data
            if (finalSetNewPasswordResponse.success === true) {
                toast.success(finalSetNewPasswordResponse.message)
                navigate('/login')
                loader(false)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
                loader(false)
            }
        }
    }

    async resendEmail({ finalData, loader, setVerificationPendingPopup, setLoginData }) {
        try {
            const resendEmailResponse = await apiService.post(`/user/email-link`, finalData)
            const finalResendEmailResponse = await resendEmailResponse.data
            if (finalResendEmailResponse.success === true) {
                toast.success(finalResendEmailResponse.message)
                loader(false)
                setVerificationPendingPopup(false)
                setLoginData({
                    email: '',
                    password: '',
                })
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
                loader(false)
            }
        }
    }

    async getListOfDomain({ userID, SetCanGetDataFromStore }) {
        try {
            const response = await apiService.get(`/user/domain/${userID}`)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.userDomainList = finalResponse.data
                SetCanGetDataFromStore(true)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
            }
        }
    }

    async addDomain({ finalData, SetCanGetDataFromStore, loader, setDataForNewDomain, setRunValidator }) {
        try {
            const response = await apiService.post(`/user/domain/`, finalData)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.getListOfDomain({ userID: finalData.userId, SetCanGetDataFromStore })
                toast.success(finalResponse.message)
                loader(false)
                setDataForNewDomain({
                    domain: ""
                })
                setRunValidator(false)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
                loader(false)
            }
        }
    }

    async deleteDomain({ userID, domainID, SetCanGetDataFromStore, loader }) {
        try {
            const response = await apiService.delete(`/user/domain/${domainID}`)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.getListOfDomain({ userID: userID, SetCanGetDataFromStore })
                loader(false)
                toast.success(finalResponse.message)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
                loader(false)
            }
        }
    }

    async updateDomainStatus({ userID, finalData, domainID, SetCanGetDataFromStore, loader, setStatusUpdateModel }) {
        try {
            const response = await apiService.patch(`/user/domain/${domainID}`, finalData)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.getListOfDomain({ userID, SetCanGetDataFromStore })
                loader(false)
                setStatusUpdateModel(false)
                toast.success(finalResponse.message)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
                loader(false)
            }
        }
    }

    async getListOfOpenIaAPI({ userID, SetCanGetOpenAIAPIFromStore }) {
        try {
            const response = await apiService.get(`user/openai/${userID}`)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.userOpenAiAPIKeys = finalResponse.data
                SetCanGetOpenAIAPIFromStore(true)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
            }
        }
    }

    async addOpenAiAPI({ finalData, SetCanGetOpenAIAPIFromStore, loader, setApiKey, setRunValidatorForOpenAI, setNewOpenAiApiModel }) {
        try {
            const response = await apiService.post(`/user/openai/add`, finalData)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.getListOfOpenIaAPI({ userID: finalData.userID, SetCanGetOpenAIAPIFromStore })
                toast.success(finalResponse.message)
                loader(false)
                setNewOpenAiApiModel(false)
                setApiKey('')
                setRunValidatorForOpenAI(false)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
                loader(false)
            }
        }
    }

    async updateOpenAiStatus({ userID, finalData, SetCanGetOpenAIAPIFromStore, loader, setStatusUpdateModelOpenAi }) {
        try {
            const response = await apiService.patch(`/user/openai/status`, finalData)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.getListOfOpenIaAPI({ userID, SetCanGetOpenAIAPIFromStore })
                loader(false)
                setStatusUpdateModelOpenAi(false)
                toast.success(finalResponse.message)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
                loader(false)
            }
        }
    }

    async deleteOpenAiAPI({ userID, SetCanGetOpenAIAPIFromStore, loader }) {
        try {
            const response = await apiService.delete(`/user/openai/${userID}`)
            const finalResponse = await response.data
            if (finalResponse.success === true) {
                this.getListOfOpenIaAPI({ userID, SetCanGetOpenAIAPIFromStore })
                loader(false)
                toast.success(finalResponse.message)
            }
        } catch (error) {
            const isAuthFailed = errorStatusHandler(error.response.status, error.response.data.message)
            if (!isAuthFailed) {
                toast.error(error.response.data.message)
                loader(false)
            }
        }
    }
}