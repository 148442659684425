import { fabric } from 'fabric';

const CustomRectText = fabric.util.createClass(fabric.Group, {
    type: 'customRectText',

    initialize: function (options, canvas) {
        options || (options = {});

        // console.log(options, "this is the options for the custom text");

        this.canvas = canvas;

        this.paddingX = options.paddingX || 10;
        this.paddingY = options.paddingY || 10;

        this.outerRect = new fabric.Rect({
            fill: options.backgroundColor || 'transparent',
            rx: (this.canvas.width * options.borderRadius) / 100 ,
            ry: (this.canvas.width * options.borderRadius) / 100 ,
            originX: 'center',
            originY: 'center',
            stroke: options.stroke || '#000000',
            strokeWidth: options.strokeWidth || 0,
            opacity: options.btnOpacity || 0,
            alpha :1
        });

        this.innerRect = new fabric.Rect({
            fill: 'transparent',
            rx: options.borderRadius || 0,
            ry: options.borderRadius || 0,
            originX: "center",
            originY: 'center',
            stroke: 'transparent',
            strokeWidth: 0,
            opacity:0.01,
        });

        if (options.fixedWidth === false) {
            this.textS = new fabric.IText(options.text || 'Text', {
                fontSize: (options.fontSize* this.canvas.width) / 100,
                fill: options.textColor || 'white',
                originX: 'center',
                originY: 'center',
                fontFamily: options.fontFamily || 'Inter',
                lineHeight: options.lineHeight || 1,
                charSpacing: options.charSpacing || 0,
                fontWeight: options.fontWeight,
                underline: options.underline || false,
                fontStyle: options.fontStyle || 'normal',
                opacity: options.txtOpacity || 0,
                width: options.fixedWidth || 100, // Fixed width for wrapping
                textAlign: options.textAlign, // Center align text
            });
        } else {

            this.textS = new fabric.Textbox(options.text || 'Text', {
                fontSize: (options.fontSize* this.canvas.width) / 100,
                fill: options.textColor || 'white',
                originX: 'center',
                originY: 'center',
                fontFamily: options.fontFamily || 'Inter',
                lineHeight: options.lineHeight || 1,
                charSpacing: options.charSpacing || 0,
                fontWeight: options.fontWeight,
                underline: options.underline || false,
                fontStyle: options.fontStyle || 'normal',
                opacity: options.txtOpacity,
                width: options.fixedWidth || 100, // Fixed width for wrapping
                textAlign: options.textAlign, // Center align text
                backgroundColor: 'transparent'
            });
        }

        this.callSuper('initialize', [this.innerRect, this.outerRect, this.textS], options);
        this._updateDimensions(options);
    },

    _updateDimensions: function (options) {
        if (options.fixedWidth === false) {

            // console.log(this.textS)
            const textWidth = this.textS.getScaledWidth();
            const textHeight = this.textS.getScaledHeight();

            // console.log(Math.floor(textWidth), Math.floor(textHeight));

            this.outerRect.set({
                width: textWidth + (this.canvas.width * this.paddingX) / 100,
                height: textHeight + (this.canvas.height * this.paddingY) / 100,
            });
            this.innerRect.set({
                width: textWidth,
                height: textHeight,
            });

            this.set({
                width: this.outerRect.width,
                height: this.outerRect.height,
                linkUrl: options.linkUrl,
                left: (this.canvas.width * options.x) / 100 ,
                top: (this.canvas.height * options.y) /100 ,
            });

            if(options.from && options.from === "preview"){
                this.set({
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation : true,
                    lockScalingFlip : true,
                    lockScalingX : true,
                    lockScalingY : true,
                    lockUniScaling : true,
                    lockSkewingX : true,
                    lockSkewingY : true,
                });
            }

        } else {
            // Set fixed width for text wrapping

            // console.log("Fixed width:", this.textS);
            

            this.textS.set({
                width: (this.canvas.width * options.fixedWidth) / 100,
                // height: 'auto',
                backgroundColor: 'transparent',
            });

            const textHeight = this.textS.calcTextHeight(); // Calculate text height
            const textWidth = this.textS.getScaledWidth(); // Calculate text width

            // console.log("Text width:", Math.floor(textWidth));

            // console.log("Wrapped text height:", Math.floor(textHeight));

            // console.log(this.paddingX, this.paddingY, "this is all paddding fot thsi ");

            this.outerRect.set({
                width: (this.canvas.width * options.fixedWidth) / 100 + (this.canvas.width * this.paddingX) / 100,
                height: textHeight + (this.canvas.width * this.paddingY) / 100,
            });
            this.innerRect.set({
                width: (this.canvas.width * options.fixedWidth) / 100,
                height: textHeight,
            });

            this.set({
                width: this.outerRect.width,
                height: this.outerRect.height,
                linkUrl: options.linkUrl,
                left: (this.canvas.width * options.x) / 100 ,
                top: (this.canvas.height * options.y) / 100 ,
            });
            if(options.from && options.from === "preview"){
                this.set({
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation : true,
                    lockScalingFlip : true,
                    lockScalingX : true,
                    lockScalingY : true,
                    lockUniScaling : true,
                    lockSkewingX : true,
                    lockSkewingY : true,
                });
            }
        }

        // this.canvas && this.canvas.renderAll();
    },


});

export default CustomRectText;
